<template>
  <div>
    <b-card title="Client Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Client
                </th>
                <td>
                  {{ client.clientName }}
                </td>
              </tr>
              <tr v-if="client.firstSaveSetJobStart">
                <th>First SaveSet job started on</th>
                <td>
                  {{ client.firstSaveSetJobStart | formatDateTimeTz(asup.timezone) }}
                  <feather-icon
                    v-b-popover.hover="'The actual date of the first SaveSet job of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="client.lastSaveSetJobStart">
                <th>Last SaveSet job started on</th>
                <td>
                  {{ client.lastSaveSetJobStart | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr>
                <th>SaveSet Jobs</th>
                <td>
                  {{ client.saveSetJobCount }}
                  <feather-icon
                    v-b-popover.hover="'The actual number of job of this client may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card title="Workflow Matrix">
      <b-overlay :show="matrixIsLoading">
        <Matrix
          :matrix-data="workflowMatrix"
          :from="workflowMatrix.from"
          :to="workflowMatrix.to"
          :day-count="workflowMatrix.dayCount"
          :rows="workflowMatrix.rows"
          :full-height="false"
          primary-col-header-text="Workflow"
        />
      </b-overlay>
    </b-card>

    <b-card
      :title="`SaveSet Jobs (${saveSetJobs.length})`"
    >
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Status</th>
              <th>Workflow</th>
              <th>SaveSet</th>
              <th>Size</th>
              <th>Started on</th>
              <th>Ended on</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(workflowJobClient, index) in orderedWorkflowJobClients">
              <template v-for="(saveSetJob, saveSetJobIndex) in filterSaveSetJobList(workflowJobClient.saveSetJobs)">
                <tr
                  :key="`${index}_${saveSetJobIndex}`"
                >
                  <td>
                    <feather-icon
                      :icon="saveSetJob.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                      class="cursor-pointer float-left"
                      @click="toggleDetails(saveSetJob)"
                    />
                    <JobStatusIcon :status="saveSetJob.calculatedStatus" />
                  </td>
                  <td>
                    <b-link :to="{ name: 'networker-detail.workflow-detail', params: { workflowName: workflowJobClient.workflowJobWorkflow }, query: { group: workflowJobClient.workflowJobGroup, policy: workflowJobClient.workflowJobPolicy, from: $moment(timeRange.startDate).toDate(), to: $moment(timeRange.endDate).toDate()} }">
                      {{ workflowJobClient.workflowJobWorkflow }}
                    </b-link>
                  </td>
                  <td>
                    <b-link :to="{ name: 'networker-detail.saveset-job-detail', params: { wfJobId: workflowJobClient.workflowJobId, client: workflowJobClient.clientName, saveSetJobId: saveSetJob.jobId }}">
                      {{ saveSetJob.saveSet }}
                    </b-link>
                  </td>
                  <td>{{ saveSetJob.size | prettyBytes }}</td>
                  <td>{{ saveSetJob.start | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ saveSetJob.end | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ saveSetJob.duration | formatClrTimeSpan }}</td>
                </tr>
                <tr
                  v-if="saveSetJob.showDetails"
                  :key="`${index}_${saveSetJobIndex}_detail`"
                >
                  <td
                    colspan="7"
                  >
                    <div class="row">
                      <div class="col">
                        <dl>
                          <dt>Job Id</dt>
                          <dd>{{ saveSetJob.jobId }}</dd>

                          <dt>Started on</dt>
                          <dd>{{ saveSetJob.start | formatDateTimeTz(asup.timezone) }}</dd>

                          <dt>Ended on</dt>
                          <dd>{{ saveSetJob.end | formatDateTimeTz(asup.timezone) }}</dd>

                          <dt>Duration</dt>
                          <dd>{{ saveSetJob.exitCode | formatClrTimeSpan }}</dd>
                        </dl>
                      </div>
                      <div class="col">
                        <dt>Exit Code</dt>
                        <dd>{{ saveSetJob.exitCode || '-' }}</dd>

                        <dt>Status</dt>
                        <dd>{{ saveSetJob.status || '-' }}</dd>

                        <dt>Job state</dt>
                        <dd>{{ saveSetJob.jobState || '-' }}</dd>

                        <template v-if="saveSetJob.parserState">
                          <dt>Parser state</dt>
                          <dd>{{ saveSetJob.parserState }}</dd>
                        </template>

                        <template v-if="saveSetJob.parserNote">
                          <dt>Parser note</dt>
                          <dd>{{ saveSetJob.parserNote }}</dd>
                        </template>
                      </div>
                      <div class="col">
                        <span style="white-space: pre-wrap">{{ saveSetJob.jobLog }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BOverlay, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'

import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import moment from '@/libs/moment'
import NetWorkerService from '@/service/networker.service'
import Matrix from '@/views/asup/backup-software/components/Matrix.vue'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, DateRangePicker, Matrix, BOverlay, BLink, JobStatusIcon,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      workflowMatrix: {},
      matrixIsLoading: false,
      workflowJobClients: [],
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    clientName() {
      return this.$route.params.clientName
    },
    saveSetJobs() {
      if (!this.workflowJobClients || this.workflowJobClients.length === 0) {
        return []
      }

      return this.workflowJobClients.flatMap(x => x.saveSetJobs)
    },
    orderedWorkflowJobClients() {
      return this.workflowJobClients
        .concat()
        .sort((a, b) => (moment(a.start) > moment(b.start) ? -1 : 1))
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment.utc(this.$route.query.from).toDate(),
        endDate: moment.utc(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    toggleDetails(saveSetJob) {
      // eslint-disable-next-line no-param-reassign
      this.$set(saveSetJob, 'showDetails', !saveSetJob.showDetails)
    },
    filterSaveSetJobList(saveSetJobList) {
      if (this.onlyErrors === true) {
        return saveSetJobList.filter(x => x.calculatedStatus !== 0)
      }

      return saveSetJobList
    },
    loadData() {
      NetWorkerService.getClientAsync(this.asup.id, this.clientName, { disableTenantFilter: true })
        .then(result => {
          this.client = result
        })

      NetWorkerService.getWorkflowJobClientListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        client: this.clientName,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed saveSetJobs
          for (let i = 0; i < result.items.length; i += 1) {
            for (let j = 0; j < result.items[i].saveSetJobs.length; j += 1) {
              if (result.items[i].saveSetJobs[j].calculatedStatus === 30) {
                // eslint-disable-next-line no-param-reassign
                result.items[i].saveSetJobs[j].showDetails = true
              }
            }
          }

          this.workflowJobClients = result.items
        })

      this.matrixIsLoading = true
      NetWorkerService.getClientWorkflowMatrixAsync(this.asup.id, this.clientName, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        onlyErrors: this.onlyErrors,
      }, { disableTenantFilter: true })
        .then(result => {
          /* Normalize data  */
          result.rows.forEach(row => {
            // eslint-disable-next-line no-param-reassign
            row.routeTo = {
              name: 'networker-detail.workflow-detail',
              params: {
                workflowName: row.properties.wfJobWorkflow,
              },
              query: {
                group: row.properties.wfJobGroup,
                policy: row.properties.wfJobPolicy,
                from: moment.utc(this.timeRange.startDate).format('YYYY-MM-DD'),
                to: moment.utc(this.timeRange.endDate).format('YYYY-MM-DD'),
              },
            }
          })

          this.workflowMatrix = result
        })
        .finally(() => {
          this.matrixIsLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
