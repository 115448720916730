<template>
  <div class="monitor-status-icon">
    <feather-icon
      :class="{ 'shouldrun': status === 40 , 'danger': status === 30 , 'warning': status === 20, 'active': status === 10,'success': status === 0}"
      icon="CircleIcon"
    />
    <span
      v-if="text"
      class="ml-1"
      :title="text"
    >
      <b-link
        v-if="to"
        :to="to"
      >{{ text }}</b-link>
      <span v-else>{{ text }}</span>
    </span>
  </div>
</template>

<script>

import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    status: {
      type: Number,
      default: () => null,
    },
    text: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: () => null,
    },
  },
}
</script>

<style>
  .monitor-status-icon {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>

<style scoped>
  .active {
    fill: var(--info);
    color: var(--info);
  }
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
  .shouldrun {
    fill: #a000a0;
    color: #a000a0;
  }
</style>
