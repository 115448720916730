<template>
  <div
    :style="{ height: height, cursor: enableClick ? 'pointer' : 'default' }"
    style="width:100%;"
    @click="$emit('click')"
  >
    <div
      v-if="succeededCount > 0"
      :style="{ width: `${partWidthPercentage}%`, height: height}"
      :class="{ 'part-last-status' : hasMultipleStatus && lastStatus === 0 }"
      class="part part-success"
      :title="`${succeededCount} succeeded`"
    />
    <div
      v-if="activeCount > 0"
      :style="{ width: `${partWidthPercentage}%`, height: height}"
      :class="{ 'part-last-status' : hasMultipleStatus && lastStatus === 10 }"
      class="part part-active"
      :title="`${activeCount} active`"
    />
    <div
      v-if="warningCount > 0"
      :style="{ width: `${partWidthPercentage}%`, height: height}"
      :class="{ 'part-last-status' : hasMultipleStatus && lastStatus === 20 }"
      class="part part-warning"
      :title="`${warningCount} warnings`"
    />
    <div
      v-if="failedCount > 0"
      :style="{ width: `${partWidthPercentage}%`, height: height}"
      :class="{ 'part-last-status' : hasMultipleStatus && lastStatus === 30 }"
      class="part part-failed"
      :title="`${failedCount} failed`"
    />
    <div
      v-if="shouldRun === true"
      :style="{ width: `${partWidthPercentage}%`, height: height}"
      class="part part-shouldrun"
      title="Did not run"
    />
  </div>
</template>

<script>
export default {
  props: {
    succeededCount: {
      type: Number,
      default: 0,
    },
    activeCount: {
      type: Number,
      default: 0,
    },
    warningCount: {
      type: Number,
      default: 0,
    },
    failedCount: {
      type: Number,
      default: 0,
    },
    lastStatus: {
      type: Number,
      default: () => null,
    },
    shouldRun: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '22px',
    },
    enableClick: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    partCount() {
      let count = 0
      if (this.succeededCount > 0) {
        count += 1
      }
      if (this.activeCount > 0) {
        count += 1
      }
      if (this.warningCount > 0) {
        count += 1
      }
      if (this.failedCount > 0) {
        count += 1
      }
      if (this.shouldRun) {
        count += 1
      }

      return count
    },
    partWidthPercentage() {
      return 100 / this.partCount
    },
    hasMultipleStatus() {
      return this.partCount > 1
    },
  },
}
</script>

<style>
    .part {
        float: left;
    }

    .part.part-success {
        background-color: var(--success);
    }

    .part.part-active {
        background-color: var(--info);
    }

    .part.part-warning {
        background-color: var(--warning);
    }

    .part.part-failed {
        background-color: var(--danger);
    }

    .part.part-shouldrun {
        background-color: #a000a0;
    }

    .part.part-last-status {
        opacity: 0.8;
        background-size: 4px 4px;
    }

    .part.part-failed.part-last-status {
        background-color: var(--danger);
        background-image: repeating-linear-gradient(45deg, #000000 0, #000000 0.4px, var(--danger) 0, var(--danger) 50%);
    }

    .part.part-warning.part-last-status {
        background-color: var(--danger);
        background-image: repeating-linear-gradient(45deg, #000000 0, #000000 0.4px, var(--warning) 0, var(--warning) 50%);
    }

    .part.part-active.part-last-status {
        background-color: var(--danger);
        background-image: repeating-linear-gradient(45deg, #000000 0, #000000 0.4px, var(--info) 0, var(--info) 50%);
    }

    .part.part-success.part-last-status {
        background-color: var(--danger);
        background-image: repeating-linear-gradient(45deg, #000000 0, #000000 0.4px, var(--success) 0, var(--success) 50%);
    }
</style>
