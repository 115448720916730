<template>
  <div class="backup-software-matrix">
    <div
      class="table-responsive matrix-wrapper"
      :class="{'full-height': fullHeight}"
    >
      <table
        class="table table-sm table-bordered matrix"
      >
        <thead
          class="sticky-top"
          style="top: -3px;"
        >
          <tr>
            <th
              :style="{ 'max-width': primaryColMaxWidth, 'min-width': primaryColMinWidth }"
              class="sticky"
            >
              {{ primaryColHeaderText }}
            </th>
            <th
              v-for="index in timeRangeDiffDays"
              :key="index"
            >
              <div
                class="vertical"
              >
                {{ getTableHeaderShortDate(index - 1) }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, rowIndex) in pagedRows">
            <tr :key="rowIndex">
              <td
                class="sticky"
                :style="{ 'max-width': primaryColMaxWidth, 'min-width': primaryColMinWidth }"
              >
                <feather-icon
                  v-if="row.subRows && row.subRows.length > 0"
                  :icon="row.showSubrows === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  class="cursor-pointer float-left"
                  @click="toggleSubrows(row)"
                />
                <StatusIcon
                  :status="row.status"
                  :text="row.displayName"
                  :to="row.routeTo"
                />
              </td>
              <td
                v-for="index in timeRangeDiffDays"
                :key="index"
                class="status-cell-wrapper"
              >
                <StatusCell
                  :succeeded-count="row.cols[index - 1].succeededCount"
                  :active-count="row.cols[index - 1].activeCount"
                  :warning-count="row.cols[index - 1].warningCount"
                  :failed-count="row.cols[index - 1].failedCount"
                  :last-status="row.cols[index - 1].lastStatus"
                  :should-run="row.cols[index - 1].shouldRun"
                  :enable-click="enableClick && !row.cols[index - 1].disableClick"
                  @click="onClickStatusCell(row, index - 1)"
                />
              </td>
            </tr>
            <template
              v-for="(subRow, subRowIndex) in row.subRows"
              v-show="row.showSubrows === true"
            >
              <tr
                v-if="row.subRows && row.subRows.length > 0"
                v-show="row.showSubrows === true"
                :key="`${rowIndex}_${subRowIndex}`"
              >
                <td
                  class="pl-2 sticky"
                  :style="{ 'max-width': primaryColMaxWidth, 'min-width': primaryColMinWidth }"
                >
                  <StatusIcon
                    :status="subRow.status"
                    :text="subRow.displayName"
                    :to="subRow.routeTo"
                    class="ml-2"
                  />

                </td>
                <td
                  v-for="index in timeRangeDiffDays"
                  :key="index"
                  class="status-cell-wrapper"
                >
                  <StatusCell
                    :succeeded-count="subRow.cols[index - 1].succeededCount"
                    :active-count="subRow.cols[index - 1].activeCount"
                    :warning-count="subRow.cols[index - 1].warningCount"
                    :failed-count="subRow.cols[index - 1].failedCount"
                    :last-status="subRow.cols[index - 1].lastStatus"
                    :should-run="subRow.cols[index - 1].shouldRun"
                    :enable-click="enableClick && !row.cols[index - 1].disableClick"
                    @click="onClickStatusCell(subRow, index - 1)"
                  />
                </td>
              </tr>
            </template>
          </template>
        </tbody>

      </table>
    </div>

    <b-form
      v-if="orderedRows.length > rowsPerPage"
      inline
    >
      <b-form-group>

        <b-pagination
          v-model="currentPage"
          class="mt-1"
          :total-rows="orderedRows.length"
          :per-page="rowsPerPage"
        />
      </b-form-group>
      <b-form-group>
        <b-form-select
          v-model="rowsPerPage"
          class="ml-1"
          :options="pageSizeOptions"
          size="sm"
        />
      </b-form-group></b-form>

  </div>
</template>

<script>
import {
  BPagination, BFormSelect, BForm, BFormGroup,
} from 'bootstrap-vue'

import moment from '@/libs/moment'
import StatusIcon from './MatrixStatusIcon.vue'
import StatusCell from './MatrixStatusCell.vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BForm,
    BFormGroup,
    StatusIcon,
    StatusCell,
  },
  props: {
    from: {
      type: [String, Date],
      required: false,
      default: () => new Date(),
    },
    to: {
      type: [String, Date],
      required: false,
      default: () => new Date(),
    },
    dayCount: {
      type: Number,
      required: false,
      default: 0,
    },
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    primaryColMaxWidth: {
      type: String,
      default: '320px',
    },
    primaryColMinWidth: {
      type: String,
      default: '250px',
    },
    primaryColHeaderText: {
      type: String,
      default: 'Client',
    },
    fullHeight: {
      type: Boolean,
      default: () => true,
    },
    defaultRowsPerPage: {
      type: Number,
      default: 200,
    },
    enableClick: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentPage: 1,
      rowsPerPage: this.$props.defaultRowsPerPage,
    }
  },
  computed: {
    startDate() {
      return this.from
    },
    endDate() {
      return this.to
    },
    timeRangeDiffDays() {
      return this.dayCount
    },
    orderedRows() {
      if (this.rows) {
        return this.rows
          .concat()
          .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      }
      return []
    },
    pagedRows() {
      return this.orderedRows.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage)
    },
    pageSizeOptions() {
      // default options
      const options = [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 500, text: 500 },
        { value: 1000, text: 1000 },
        { value: 999999999, text: 'All' },
      ]

      // add additional option from input properties if not exists
      if (!options.some(x => x.value === this.rowsPerPage)) {
        options.push({ value: this.rowsPerPage, text: this.rowsPerPage })
      }

      // order options by value
      return options.concat().sort((a, b) => (a.value > b.value ? 1 : -1))
    },
  },
  methods: {
    getTableHeaderShortDate(dayIndex) {
      return moment(this.startDate).add(dayIndex, 'days').format('DD.MM')
    },
    toggleSubrows(row) {
      // eslint-disable-next-line no-param-reassign
      this.$set(row, 'showSubrows', !row.showSubrows)
    },
    onClickStatusCell(row, dayIndex) {
      if (this.enableClick === true) {
        this.$emit('cell-click',
          {
            row,
            colIndex: dayIndex - 1,
            day: moment(this.startDate).add(dayIndex, 'days'),
          })
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/scss/backup-software-matrix.scss';
</style>
